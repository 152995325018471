
import RegistrationComponent from '@/components/modules/RegistrationComponent';
export default {
    components: {
        RegistrationComponent
    },
    props: {
        bgImage: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            recurringAmt: '7.99',
            isStation: false,
        }
    },
    methods: {
        recrnAmt(value) {
            this.recurringAmt = value
        },
        stations(value) {
            this.isStation = value
        }
    }

}
