
import HeroArea from '@/components/modules/HeroArea'
export default {
    components: {
        HeroArea
    },
    head() {
        return {
            title: "Check Mot | CheckMot.com | Mot Checker | Check Your Mot Online with CheckMot.com",
            meta: [{
                hid: 'description',
                name: 'description',
                content: "Check MOT and Tax of UK vehicle, check when your mot is due and the history of your car by using the Check Mot search function. Check MOT online."
            },
            {
                hid: 'og:title',
                property: 'og:title',
                content: "Check Mot | CheckMot.com | Mot Checker | Check Your Mot Online with CheckMot.com"
            },
            {
                hid: 'og:description',
                property: 'og:description',
                content: "Check MOT and Tax of UK vehicle, check when your mot is due and the history of your car by using the Check Mot search function. Check MOT online."
            },
            {
                hid: 'twitter:title',
                property: 'twitter:title',
                content: "Check Mot | CheckMot.com | Mot Checker | Check Your Mot Online with CheckMot.com"
            },
            {
                hid: 'twitter:description',
                property: 'twitter:description',
                content: "Check MOT and Tax of UK vehicle, check when your mot is due and the history of your car by using the Check Mot search function. Check MOT online."
            }
            ],
        }
    },
    middleware: [
        // function({ redirect }) {
        //     redirect('/index');
        // },
    ],
}
